body {
  margin: 0;
  background-color: #fff;
}
body,
input[type="password"],
input[type="email"],
input[type="text"],
input[type="number"] {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  color: #272937;
}
a {
  font-weight: 500;
  color: #05ADBF;
}
h1 {
  margin: 0;
  line-height: 44px;
  font-size: 40px;
  font-weight: 600;
}
.d-block { display: block; }
.d-flex { display: flex; }
.jc-sb { justify-content: space-between; }
.text-muted {
  color: #27293759;
}
.mt-1 { margin-top: 8px; }
.mt-4 { margin-top: 32px; }
.mb-4 { margin-bottom: 32px; }
.mb-8 { margin-bottom: 64px; }
.fs-5 { font-size: 16px; line-height: 24px; }
.fs-6 { font-size: 12px; line-height: 16px; }